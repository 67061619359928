import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Nav from "../components/nav"

import "./index.css";
import "./help.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Hero from "../components/hero-standard"
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Help" />
        <Nav activeTab="help" />
        <div>
            <div>

              <Hero h1="Help" h2="Let's get your issue resolved" />

              <div className="about-page article">
                <div className='article-inner'>
                  <p>To ensure your issue is dealt with as quick as possible, we need to determine the type of query you have. In many cases your query may need to go direct to your transport provider, who use our ticketing platform to issue/sell bus tickets.</p>
                  <h2>I have a query about the bus service</h2>
                  <p><strong>Any query regarding the below items needs to go to your transport provider</strong>. This is typically your bus/coach company but could be your school/college - whichever organisation is responsible for organising your travel and issuing tickets.</p>
                  <div className="two-col">
                    <ul>
                      <li>Timetable</li>
                      <li>Route/bus stops</li>
                      <li>Ticket prices</li>
                      <li>Ticket options</li>
                      <li>Ticket availability</li>
                    </ul>
                    <ul>
                      <li>Payments</li>
                      <li>Renewals</li>
                      <li>Discounts/bursaries</li>
                      <li>Refunds/cancellations</li>
                      <li>Lost property</li>
                    </ul>
                  </div>
                  <p>Contacting ShuttleID regarding the above items will delay in your query being resolved as ultimately it can only be handled by your transport provider.</p>

                  <h2>I have a technical query about purchasing or using my ticket</h2>
                  <p>The ShuttleID support team can only assist with technical queries regarding the purchase process and the use of the ticket itself.</p>
                  <p>For guidance on how to complete your purchase or how to use your ticket, visit the <a href="https://help.shuttleid.uk">Help Portal</a>. Most queries are answered here and will allow you to resolve your issue immediately, without further delay.</p>
                  <p>The <a href="https://help.shuttleid.uk">Help Portal</a> is available 24/7, enabling you to answer your query straight away, when it's convenient for you.</p>
                  <p><a href="https://help.shuttleid.uk" className="btn">Visit Help Portal</a></p>
                  <p>If you are purchasing a ticket via ShuttleID for the first time, please contact your transport provider for a link to their ticket listings page on the ShuttleID Passenger Portal.</p>
                  <p>Your transport provider may advertise this directly on their own website, social media or via an email to you.</p>
                  <p><a href="https://passenger.shuttleid.uk" className="btn">Visit Passenger Portal</a></p>
                  <p>For any other technical issues that are not already covered by the help portal, please email <a href="mailto:support@shuttleid.uk">support@shuttleid.uk</a>. Our support team will endeavour to respond to your request within 24 hours.</p>

                  <h2>I have a query about the system for use in our organisation</h2>
                  <p>If you are a transport provider and want to know more about using ShuttleID, our friendly sales team can help advise you.</p>
                  <p>Contact <a href="mailto:sales@shuttleid.uk">sales@shuttleid.uk</a> or call 0333 344 9868 Mon-Fri 9AM-5PM.</p>
                </div>
              </div>

              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
